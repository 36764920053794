<template>
  <div class="home">
    <div class="icon" :style="icon">
      <img
          v-if="start"
          class="pic2"
          src="../assets/image/home/home1.png"
      />
      <img
          v-else
          class="pic2"
          src="../assets/image/home/home2.png"
      />
      <div class="fsimg" :style="imageUrl"   v-if="!start"></div>
    </div>
  </div>
</template>

<script>
import {initHome} from '../api/home'

export default {
  data() {
    return {
      icon: '',
      imageUrl: '',
      start:true
    }
  },
  created() {
    initHome().then(res => {
      let imageUrl = res.imageUrl
      this.imageUrl = " background-image: url(" + imageUrl + ");"
    })
  },
  mounted() {
    let SidNumber = this.$route.query.SidNumber



    setTimeout(()=>{
      if(SidNumber){
        this.$router.push({
          path:'/lookwork',
          query:{
            SidNumber
          }
        })
      }else {
        let ins=this.getRandom(0,2)
        if (ins==0){
          this.start=true
        }else {
          this.start=false
        }
        setTimeout(() => {
          this.icon = 'opacity:1'
        }, 10)
        setTimeout(()=>{
          this.icon='opacity:0;transition: 3s all;'
        },1000)
        setTimeout(()=>{
          this.$router.push('/login')
        },3000)
      }
    },300)

  },
  methods: {
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;

  .icon {
    opacity: 0;
    width: 240px;
    height: 250px;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -125px;
    left: 50%;
    margin-left: -120px;

    .pic2 {
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .fsimg {
      width: 35px;
      height: 23px;
      position: absolute;
      right: 15.5px;
      top: 16px;
      background-size: 100% 100%;

    }
  }

}

</style>
